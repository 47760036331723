import React from "react";
import "./App.css";
import "./styles/main.css";
import Chat from "./Chat";

function App() {
  return <Chat />;
}

export default App;
